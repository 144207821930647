import * as React from "react";

import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkLayoutFlexProps } from "@egds/react-core/layout-flex";
import { UitkLayoutConditionalGridTrack } from "@egds/react-core/layout-grid";

import { EditorialFlexModule, FlexModuleResult } from "typings/microserviceModels/content-flex-module";

import { ScrimCard } from "components/shared/ScrimCard/ScrimCard";
import { ScrimCardTransparent } from "components/shared/ScrimCardTransparent/ScrimCardTransparent";
import { UitkScrimType } from "@egds/react-core/scrim";
import { FAILED_MODEL_RENDER } from "src/config/systemEvents";
import { NOOP_LOGGER as logger } from "bernie-logger";

import { observer } from "mobx-react";
import { withStores } from "stores";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import EditorialBanner from "../EditorialBanner/EditorialBanner";

import { EGClickStreamTracker } from "src/components/utility/analytics/EGClickStream/EGClickStreamTracker";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";
import { SubtitleProps, TitleProps } from "./typings";
import { EditorialBannerProps } from "../EditorialBanner/typings";

const Subtitle: React.FC<SubtitleProps> = ({ subtitle, fontSize = 500 }) => (
  <UitkText size={fontSize} theme="inverse">
    {subtitle}
  </UitkText>
);

const Title: React.FC<TitleProps> = ({ title, fontSize = 3 }) => (
  <UitkHeading className="editorial-hero-heading" size={fontSize}>
    {title}
  </UitkHeading>
);
const CtaButton = EGClickStreamTracker(UitkSecondaryButton);

export const EditorialHeroBanner = withStores("flexModuleModelStore")(
  observer((props: EditorialBannerProps) => {
    try {
      const { templateComponent, flexModuleModelStore } = props;
      const {
        metadata: { id },
        config: { fmId, view },
      } = templateComponent;
      const model = flexModuleModelStore.getModel(id) as FlexModuleResult;
      const isBexHeroFullBleedEnabled = view === "banner-hero-v2";
      const moduleName = "EditorialHeroBanner";

      /* istanbul ignore next*/
      if (!model) {
        return null;
      }

      // Re-use the existing EditorialBanner since it is almost the same logic
      if (isBexHeroFullBleedEnabled) {
        return (
          <TrackedVisibility rfrrId={`${moduleName}.${id}`} moduleName={moduleName}>
            <EditorialBanner {...props} isHero={true} />
          </TrackedVisibility>
        );
      }

      // @ts-ignore
      const [{ media, text }] = model.items;
      const { padding } = model as EditorialFlexModule;
      /**
       * This is a temporary work around due to limitations of the call-to-action model that will be addressed in the future.
       * Parsing data from contributed HTML content should not be considered an accepted approach to be repeated elsewhere
       */
      const htmlText = text as string;

      const [, title] = /<h3>(.*?)<\/h3>/g.exec(htmlText) || [];
      const [, subtitle] = /<p class="subtitle">(.*?)<\/p>/g.exec(htmlText) || [];
      const [, ctaLabel] = /<p><button>(.*?)<\/button><\/p>/g.exec(htmlText) || [];
      const [, subtitleTerm] = /<p class="subtitle-term">(.*?)<\/p>/g.exec(htmlText) || [];

      // Media content
      const [desktopMedia, mobileMedia] = media;
      const { mediaUrl: mobileMediaURL, mediaAlt: mobileMediaAtlText } = mobileMedia;
      const { clickUrl } = desktopMedia;

      // Component display properties
      const hasNoPadding = padding === SpacingSize.NONE;
      const spacing: UitkSpacingProps = { margin: { inline: "three", blockstart: hasNoPadding ? "unset" : "six" } };

      const justifyContent: UitkLayoutFlexProps["justifyContent"] = "center";

      const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 1, medium: 2 };

      const scrimCardProps = {
        id: `editorial-banner-hero-${fmId}`,
        className: "EditorialHeroBanner",
        titleSlot: <Title title={title} />,
        subtitleSlot: <Subtitle subtitle={subtitle} />,
        footerSlot: (
          <UitkSpacing margin={{ blockend: "one" }}>
            <div>
              <UitkSpacing margin={{ block: "three" }}>
                <CtaButton
                  moduleName={moduleName}
                  linkName="CTA button clicked"
                  rfrr={`home.${moduleName}`}
                  tag="a"
                  href={clickUrl}
                >
                  {ctaLabel}
                </CtaButton>
              </UitkSpacing>
              {subtitleTerm && <Subtitle subtitle={subtitleTerm} fontSize={200} />}
            </div>
          </UitkSpacing>
        ),
        spacing,
        mainGridColumns,
        padding,
        justifyContent,
      };

      return (
        <Viewport>
          <ViewSmall>
            <ScrimCard
              {...scrimCardProps}
              titleSlot={<Title title={title} fontSize={4} />}
              subtitleSlot={<Subtitle subtitle={subtitle} fontSize={400} />}
              aspectRatio={UitkFigureAspectRatioType.R4_3}
              backgroundImageURL={mobileMediaURL}
              altText={mobileMediaAtlText}
              scrimType={UitkScrimType.OVERLAY}
              justifyContent={"start"}
              border={false}
            />
          </ViewSmall>
          <ViewMedium>
            <ScrimCardTransparent {...scrimCardProps} />
          </ViewMedium>
          <ViewLarge>
            <ScrimCardTransparent {...scrimCardProps} />
          </ViewLarge>
        </Viewport>
      );
    } catch (error) {
      logger.logEvent(
        FAILED_MODEL_RENDER,
        "Unexpected error occurred while rendering EditorialHeroBanner module.",
        error
      );

      return null;
    }
  })
);

export default EditorialHeroBanner;

import * as React from "react";

import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem, UitkLayoutFlexProps } from "@egds/react-core/layout-flex";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";

export type ScrimCardTransparentProps = {
  id: string;
  dataTestId?: string;
  titleSlot?: React.ReactNode | React.ReactNode[];
  subtitleSlot?: React.ReactNode | React.ReactNode[];
  footerSlot?: React.ReactNode | React.ReactNode[];
  className: string;
  spacing?: UitkSpacingProps;
  domRef?: React.RefObject<HTMLElement> | null;
  justifyContent?: UitkLayoutFlexProps["justifyContent"];
  mainGridColumns?: UitkLayoutConditionalGridTrack;
  padding?: string;
};

export const ScrimCardTransparent: React.FC<ScrimCardTransparentProps> = ({
  id,
  dataTestId = "scrim-card-transparent",
  className,
  titleSlot,
  subtitleSlot = null,
  footerSlot = null,
  spacing = {},
  domRef = null,
  justifyContent = "space-between",
  mainGridColumns = { small: 1, medium: 2 },
  padding,
}) => {
  const subheadingCols: UitkLayoutConditionalGridSpan = { small: 1, medium: 1 };
  const hasNoPadding = padding === SpacingSize.NONE;
  const blockendSpacing = hasNoPadding ? "unset" : "six";

  return (
    <UitkSpacing padding={{ blockend: blockendSpacing }}>
      <section data-testid={dataTestId} id={id} ref={domRef} className={className}>
        <UitkSpacing {...spacing}>
          <UitkLayoutFlex direction="column" justifyContent={justifyContent} style={{ height: "inherit" }}>
            <UitkLayoutFlexItem>
              <div>
                {titleSlot && titleSlot}
                {subtitleSlot && (
                  <UitkSpacing padding={{ blockstart: "three" }}>
                    <UitkLayoutGrid columns={mainGridColumns}>
                      <UitkLayoutGridItem colSpan={subheadingCols}>{subtitleSlot}</UitkLayoutGridItem>
                    </UitkLayoutGrid>
                  </UitkSpacing>
                )}
              </div>
            </UitkLayoutFlexItem>

            {footerSlot && (
              <UitkLayoutFlexItem>
                <div>{footerSlot}</div>
              </UitkLayoutFlexItem>
            )}
          </UitkLayoutFlex>
        </UitkSpacing>
      </section>
    </UitkSpacing>
  );
};
